
import { getPrivateLabelId } from '@tcc/shared/src/helpers/page';
import { findCookie } from '@exp/exp-utils/helper/cookie';
import { hasAnalyticsConsent, hasMarketingConsent } from '@tcc/shared/src/helpers/policy';
import { buildCorporateUrl } from '@tcc/shared/src/helpers/page';
import { map as objectMap, merge } from '@exp/exp-utils/helper/object';
import page from '@tcc/shared/src/traffic/eventPageProperties';

import { getDeploymentConfig } from './deploymentConfig';
import { getAccountId } from './helper';
/**
 * POC Controller should know the following:
 * - isEnabled
 *   - aka whether or not to activate the Gtag Controller
 *     - (plid === 1 AND currentMarket in _deploymentConfig market
 * - all the current deployments (ga4, sgtm, or both)
 * - how to handle all the trackers in the deployments
 * - Consent Mode
 *   - how to handle _initDataLayer and loading of gtag.js
 *   - how to update consent mode based off consent
 * - how to handle gtag config records
 */
/**
 * In correct site (plid === 1) && currentMarket === current market of deployment
 * if true, lookup the currentActiveDeployment
 *  determine the controller state and how to handle that state
 *      - consent mode: load order of configs and ga
 *      - how each tracker should be handled (config record, page view, page events, ecomm events)
 *      EDGE CASES:
 *          - if consent mode is ACTIVE: ensure default consent record and gtag is loaded (regardless of consent)
 *          - if UA-PROD and UA-EXP (server-side) are running in parallel:
 *              - ADD: TEST UA config w/ transport_url prop
 *              - UPDATE: send_to prop w/ [UA-PROD, UA-EXP] on page_view, events, ecomm events
 *                  * NOTE: no need to duplicate event record to _analyticsDataLayer since it's the same event mapping
 *          - if GA4 client-side
 *              - ADD: GA4 Config w/ property groups: 'groupGa4'
 *              - UPDATE: Duplicate event record to _analyticsDataLayer w/ updated event object mapping
 *          - if GA4 server-side
 *              - ADD: GA4 Config w/ transport_url and first_party_collection prop
 *              - UPDATE: Duplicate HIT w/ update event object mapping
 *                  * NOTE: if GA4 client-side and GA4 server-side are running in parallel
 *                          - UPDATE: send_to prop: [G-CLIENT_SIDE, G-SERVER_SIDE]
 *                          - no need to duplicate events to _analyticsDataLayer since it's the same event mapping
 *
 * MileStone #1: GA4 client side - In Progress
 * MileStone #2: Consent Mode
 * MileStone #3: GA4 w/ SGTM
 * MileStone #4: UA w/ SGTM
*/

const _serverEndpoint = 'g.sst';

let _market,
  _deploymentConfig,
  _isEnabled = false,
  _trackers,
  _currentDeployment,
  isConsentModeEnabled = false;

const init = () => {
  _market = findCookie('market');
  _deploymentConfig = getDeploymentConfig();

  // Only initialize for GoDaddy (plid == 1) and only if there is a deployment configuration for the current market.
  if (getPrivateLabelId() === '1' && _deploymentConfig.deploymentMarkets.indexOf(_market) !== -1) {

    // Lookup deployment configuration based on market.
    _currentDeployment = _deploymentConfig.deployments.filter(deployment => deployment.market === _market)[0];

    // Set active flag for controller
    _isEnabled = _currentDeployment && (_currentDeployment.consentMode || (_currentDeployment.trackers.length > 1));
    if (_isEnabled) {
      // Set consent mode for current deployment
      isConsentModeEnabled = _currentDeployment.consentMode;

      // Load ga4 & sgtm tracker configuration for current deployment
      _trackers = _currentDeployment.trackers.reduce((previousTracker, currentTracker) => {
        (previousTracker[currentTracker.type] = previousTracker[currentTracker.type] || []).push(currentTracker);
        return previousTracker;
      }, {});
    }
  }
};

const _eidActionMap = {
  // Promotion Clicks
  'traffic.tcc.instrumentation.internal_promotion.click': 'select_promotion',
  // Promotion Impressions
  'traffic.tcc.instrumentation.internal_promotion.impression': 'view_promotion',
  // Domain Search
  'find.sales.search_bar.search.click': 'domain_search',
  // Generic Conversions (in scope)
  'tcc.conversion.vnext.gocentral.freetrial.signup': 'gocentral_freetrial_signup',
  'tcc.conversion.vnext.gocentral.none.first_publish': 'gocentral_first_publish',
  'tcc.conversion.ols-product_add.online_store.none.ols-product_add': 'online_store_ols_product_add',
  'tcc.conversion.pro.webpro.none.signup': 'webpro_signup',
  'tcc.conversion.cms.office-business.none.call-request': 'office_call_request',
  'tcc.conversion.cms.godaddy-social.none.call-request': 'godaddy_social_call_request',
  'tcc.conversion.cms.easy-ssl-service.none.call-request': 'easy_ssl_service_call_request',
  'tcc.conversion.cms.seo-services.none.call-request': 'seo_services_call_request',
  'gtp.smartline.freetrial.vcart.signup': 'smartline_freetrial_signup'
};

const _includedEids = Object.keys(_eidActionMap);

const _getConfigProps = (tracker) => {
  const extraProps = {};
  if (tracker.containerType === 'Sgtm') extraProps.transport_url = buildCorporateUrl({ host: 'secureserver.net', sub: _serverEndpoint });
  if (tracker.type === 'ga4') {
    extraProps.groups = 'groupGa4';
    if (tracker.containerType === 'Sgtm')
      extraProps.first_party_collection = true;
  }
  return extraProps;
};

const handleConsentMode = (type = 'default') => {
  const consentRecord = {
    ad_storage: 'denied',
    analytics_storage: 'denied'
  };
  if (type === 'update') {
    consentRecord.ad_storage = hasMarketingConsent() ? 'granted' : 'denied';
    consentRecord.analytics_storage = hasAnalyticsConsent() ? 'granted' : 'denied';
  }
  return consentRecord;
};

/**
 * @name handleConfigRecords
 * @description Handles config records. Currently only for GA4 (client side or server side)
 * @returns {Array} Containing Objects with id (tracker id) and config params property
 * @example
 * [
 *    // GA4 - server side w/ SGTM
 *    {  'G-XXXX1', {
 *          send_page_view: false,
 *          anonymize_ip: true,
 *          page_referrer: 'https://somereferrer'
 *          transport_url: 'https://sgtmserver.domain.com'
 *          groups: 'groupGa4'
 *       }
 *    },
 *    // GA4 - client side
 *    { 'G-XXXX2', {
 *          send_page_view: false,
 *          anonymize_ip: true,
 *          page_referrer: 'https://somereferrer'
 *          groups: 'groupGa4'
 *        }
 *    }
 *    // UA - server side w/ SGTM
 *    { 'UA-XXXXX', {
 *          send_page_view: false,
 *          anonymize_ip: true,
 *          page_referrer: 'https://somereferrer'
 *          transport_url: 'https://sgtmserver.domain.com'
 *        }
 *    }
 * ]
 */
const handleConfigRecords = () => {
  if (_isEnabled) {
    const _configObj = {
      send_page_view: false,
      anonymize_ip: true,
      page_referrer: page.get('referrer')
    };
    // NOTE: if we route the prod UA to sgtm, we need to ensure we update this function
    // to handle the prod UA aka the default
    // Loop through trackers within deployment config and push config records to dataLayer
    const configRecords = [];
    _currentDeployment.trackers.forEach((tracker) => {
      if (!tracker.default) {
        tracker.id = getAccountId(tracker.type + tracker.containerType);
        configRecords.push({
          id: tracker.id,
          params: merge(_configObj, _getConfigProps(tracker))
        });
      }
    });
    return configRecords;
  }
  return [];
};

/**
 * @description Transforms item object with GA4 Items Property
 * @link https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase_item
 * @param {Object} itemObj item of Items Property
 * @returns {Object} returns transformed item object
 * @example
 * // Ecommerce Example
 * // input
 * const oldItemObj = {
 *  id: '123',
 *  brand: 'some brand',
 *  category: 'some category',
 *  name: 'some name',
 *  variant: 'some variant'
 * }
 * // output
 * const updatedItemObj = {
 *  item_id: '123',
 *  item_brand: 'some brand',
 *  item_category: 'some category',
 *  item_name: 'some name',
 *  item_variant: 'some variant'
 * }
 */
const _transformGa4ItemProp = (itemObj) => {
  const ga4ItemKey = {
    id: 'item_id',
    brand: 'item_brand',
    category: 'item_category',
    name: 'item_name',
    variant: 'item_variant'
  };
  const ga4ItemObj = {};
  objectMap(itemObj, (key, value) => {
    if (ga4ItemKey[key]) {
      ga4ItemObj[ga4ItemKey[key]] = value;
    } else {
      ga4ItemObj[key] = value;
    }
  });
  return ga4ItemObj;
};

const _transformEventProps = ({ eId, eventAction, eventData = {}, eventObj = {}}) => {
  const baseGa4EventObj = {
    visitguid: eventObj.visitguid_hit,
    visitorguid: eventObj.visitorguid_hit,
    logged_in_status: eventObj.logged_in_status_hit,
    customer_agent_bool: eventObj.customer_agent_bool_hit,
    delegated_bool: eventObj.delegated_bool_hit,
    market: eventObj.market_hit,
    referrer: eventObj.referrer_hit,
    user_agent: eventObj.user_agent_hit,
    querystring: eventObj.querystring_hit,
    privatelabelid: eventObj.privatelabelid_hit,
    timestamp: eventObj.timestamp_hit,
    google_client_id: eventObj.google_client_id_hit,
    is_gd_salessite_bool: eventObj.is_gd_salessite_bool_hit,
    correlation_id: eventObj.correlation_id_hit,
    traffic_load_source: eventObj.traffic_load_source_hit,
    page_id: eventObj.page_id_hit,
    traffic_tcc_version_hit: eventObj.traffic_tcc_version_hit,
    hit_id: eventObj.hit_id_hit,
    realm: eventObj.realm_hit,
    event_action: eventAction,
    event_category: eventObj.event_category,
    content_group: eventObj.content_group1,
    transport_type: eventObj.transport_type,
    // eventObj.event_action is pulling from EID field for ecomm/generic conversion events
    event_id: eId || eventObj.event_action,
    user_properties: {
      visitorguid: eventObj.visitorguid_user_scoped,
      google_client_id: eventObj.google_client_id_user_scoped,
      existing_shopper_user: eventObj.existing_shopper_user_scoped,
      shopper_id: eventObj.shopper_id_user_scoped,
      customer_id: eventObj.customer_id_user_scoped,
      customer_agent_bool: eventObj.customer_agent_bool_hit
    },
    send_to: 'groupGa4'
  };

  if (eventObj.non_interaction)
    baseGa4EventObj.non_interaction = eventObj.non_interaction;

  // ecom events (excluding promotions)
  if (eventObj.items) {
    baseGa4EventObj.items = [];
    eventObj.items.forEach((item, index) => {
      baseGa4EventObj.items[index] = _transformGa4ItemProp(item);
    });
  }

  // ecom - promotions
  if (eventObj.promotions) {
    baseGa4EventObj.items = [];
    eventObj.promotions.forEach((item, index) => {
      baseGa4EventObj.items[index] = _transformGa4ItemProp(item);
    });
  }

  // page event - generic conversions
  if (eventData.properties) {
    baseGa4EventObj.package_category = eventData.properties.package_category;
    baseGa4EventObj.package_id = eventData.properties.package_id;
    baseGa4EventObj.transaction_id = eventData.properties.virtual_order_id;
  }

  return baseGa4EventObj;
};

/**
 * @name buildPageView
 * @description Builds Google Analytics 4 (GA4) page view event payloads
 * - Transforms event params
 * @param {Object} eventAction UA event action
 * @param {Object} eventObj event object (processed by filtermap)
 * @returns {Object} with event param property
 */
const buildPageView = (eventAction, eventObj) => {
  if (_trackers.ga4.length > 0) {
    const ga4EventObj = _transformEventProps({ eventAction, eventObj });
    ga4EventObj.referrer = eventObj.referrer_hit;
    ga4EventObj.location_uri = eventObj.location_uri_hit;
    ga4EventObj.page_path = eventObj.page_path;
    ga4EventObj.send_page_view = eventObj.send_page_view;
    return {
      params: ga4EventObj
    };
  }
};

/**
 * @description Builds generic conversion (aka 'tcc.conversion.*') and page event payloads.
 * This builder is invoked by ga.SendPageEvent
 * @param {String} eId event id
 * @param {Object} eventData event data object
 * @param {Object} eventObj event object (processed by filtermap)
 * @returns {Object} With GA4 event name and event param property
 */
const buildPageEvent = (eId, eventData, eventObj) => {
  if (_trackers.ga4.length > 0) {
    const ga4EventName = _eidActionMap[eId] ? _eidActionMap[eId] : 'generic_interaction';
    const ga4EventObj = _transformEventProps({ eId, eventAction: eId, eventData, eventObj });
    return {
      name: ga4EventName,
      params: (ga4EventName === 'generic_interaction' ? ga4EventObj : merge(eventData, ga4EventObj))
    };
  }
};

/**
 * @name buildEcommerceEvent
 * @description Builds the payload for an Ecommerce Event
 * @param {String} eventAction UA Event action (ex. 'purchase', 'add_to_cart', etc);
 * @param {Object} eventData Event data object
 * @param {Object} eventObj Event Object (processed by filtermap)
 * @returns {Object} with GA4 event name and event param property
 */
const buildEcommerceEvent = (eventAction, eventData, eventObj) => {
  if (_trackers.ga4.length > 0) {
    const ga4EventObj = _transformEventProps({
      eventAction: eventObj.event_action,
      eventData,
      eventObj });
    return {
      name: (eventAction === 'select_content' ? 'select_promotion' : eventAction),
      params: merge(eventData, ga4EventObj)
    };
  }
};

/**
 * @name isEnabled
 * @description Determines whether or not the event should be sent to POC
 * @param {String} eid Event identifier (ex. 'purchase', 'add_to_cart', etc).
 * @param {String} category Event category AKA "eventtype" in client interfaces. (ex. 'click', 'impression')
 * @returns {Boolean} where true means the event should be sent to POC
 */
const isEnabled = (eid, category) => {
  return _isEnabled && !eid
    || _includedEids.includes(eid)
    || category === 'click';
};

const _reset = () => {
  _isEnabled = false;
  _currentDeployment = undefined;
  _trackers = undefined;
  isConsentModeEnabled = false;
};

export {
  isConsentModeEnabled,
  isEnabled,
  init,
  handleConsentMode,
  buildCorporateUrl,
  buildPageEvent,
  buildEcommerceEvent,
  buildPageView,
  handleConfigRecords
};

// Private exports for testing
export {
  _market,
  _trackers,
  _currentDeployment,
  _isEnabled,
  _reset,
  _getConfigProps,
  _transformEventProps,
  _transformGa4ItemProp
};
