import _config from '@exp/exp-utils/helper/config';

_config.merge({
  'tcc.listenerDisabled': false,
  'tcc.manualPagePerf': false,
  'tcc.loadTTI': true,
  'tcc.eventDelayMs': 75,
  'tcc.consentDelayMs': 500,
  'tcc.gaAccountDev': 'UA-52262008-15',
  'tcc.gaAccountTest': 'UA-37178807-21',
  'tcc.gaAccountProd': 'UA-115508484-1',
  'tcc.gaSgtmAccountDev': 'UA-XXXXX-X',
  'tcc.gaSgtmAccountTest': 'UA-XXXXX-X',
  'tcc.gaSgtmAccountProd': 'UA-XXXXX-X',
  'tcc.ga4AccountDev': 'G-QR3LRHZZ2J',
  'tcc.ga4AccountTest': 'G-XVYQ3S2DCT',
  'tcc.ga4AccountProd': 'G-R9B47BVCG7',
  'tcc.ga4SgtmAccountDev': 'G-TPY4ZF3V04',
  'tcc.ga4SgtmAccountTest': 'G-1LZCFHGZM9',
  'tcc.ga4SgtmAccountProd': 'G-QJXPZE4TX8',
  'tcc.buildEnv': 'prod',
  'tcc.buildName': '@tcc/tcc',
  'tcc.buildVersion': '3.16.9',
  'tcc.buildDate': '2022-06-14T20:43:36.290Z',
  'tcc.splitioUrl': 'https://img6.wsimg.com/wrhs/7f77e4ddc5077a5c6c50ee67de56f864/splitio.min.js',
  'tcc.splitioApiKey': '26b1lpi1u5ifu0ug7hj9t68j43qi0b1sulf6',
  'tcc.ttiUrl': 'https://img6.wsimg.com/wrhs/ce554d2333f3801abafb32da18213ff7/tti.min.js'
});

export default _config;
