import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import page from '@tcc/shared/src/traffic/eventPageProperties';

import { getClientId } from '../../ga/tracker';

import experimentTracker from '../experimentTracking';
import VisitHelper from '../../helpers/visitHelperTcc';

class GetTrackingValuesHandler extends SchemaHandler {
  process() {
    super.process({
      ALL: () => { this._handle(); }
    });
  }

  _handle() {
    const obj = {};

    const visitHelper = new VisitHelper();
    const visitInfo = visitHelper.getVisitInfo();

    // Set visitor / visit IDs
    obj.vg = visitInfo.visitGuid;
    obj.vtg = visitInfo.visitorGuid;

    // An identifier used to tie together page request and page events. This value changes with every page request.
    obj.corrid = page.get('corrid');

    // KVP of all experiment/variant IDs
    obj.experiments = experimentTracker.getProperties();

    // Map GA client ID
    obj.gauid = getClientId();

    // Set page's trace ID
    obj.traceid = page.get('trace_id');

    if (typeof this.data.ALL.callback === 'function') {
      this.data.ALL.callback(obj);
    }
  }
}

export default GetTrackingValuesHandler;
